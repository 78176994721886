import Box, { BoxProps } from '@mui/material/Box'

export interface EllipsisBoxProps extends BoxProps {
  breakAll?: boolean
  lineClamp?: number
}

export const EllipsisBox: React.FC<EllipsisBoxProps> = ({
  breakAll = true,
  children,
  className,
  lineClamp,
  ...restBoxProps
}) => {
  return (
    <Box
      sx={{
        '& span, p, h1, h2, h3, h4, h5, h6, a': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: lineClamp ? undefined : 'nowrap',
          display: lineClamp ? '-webkit-box' : undefined,
          '-webkit-box-orient': lineClamp ? 'vertical' : undefined,
          '-webkit-line-clamp': lineClamp ? `${lineClamp}` : undefined,
          wordBreak: breakAll ? 'break-all' : 'break-word'
        }
      }}
      className={className}
      {...restBoxProps}
    >
      {children}
    </Box>
  )
}
